<template>
  <div class="">

    <MazInput v-model="PeriodoTexto"
              :placeholder="placeholder"
              :focus="TieneFoco" :autocomplete="acPeriodo"
              @focus="OnMostrarCalendario" size="sm"
              :required="required" :loading="loading">
      <div v-if="mostrarFechas" slot="icon-left">
        <router-link :to="{}" @click.native.stop="Atras" tabindex="-1" class="p-2">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>
      <div v-if="mostrarFechas" slot="icon-right">
        <router-link :to="{}" @click.native.stop="Adelante" tabindex="-1" class="p-2">
          <i class="fas fa-chevron-right"></i>
        </router-link>

      </div>
      <!--  <i slot="icon-left" type="mini"
              :appendToBody="true"  :scrollToBottom="true"
           scrollToBottom="false" :appendToBody="true"
    class="fas fa-portrait"></i>-->

    </MazInput>
    <modal v-if="MostrarCalendario" :show.sync="MostrarCalendario" ref="Modal"
           class="modal-black" :autoClose="false"
           centered
           modalContentClasses="card"
           headerClasses="card-header">
      <div slot="header">
        <h5 class="card-category">{{placeholder}}</h5>
        <h3 class="card-title">{{Titulo}}</h3>
        <span class="text-muted">{{Ayuda}}</span>
      </div>
      <template>
        <ValidationObserver v-slot="{ handleSubmit}">
          <form @submit.prevent="handleSubmit(Aceptar)">
            <MazPicker v-if="mostrarFechas" v-model="periodo" class="maz-mb-3 flex-fill "
                       placeholder="Periodo"
                       range size="sm" :error="HayError"
                       format="YYYY-MM-DD" noShortcuts
                       formatted="ddd D/MMM/YYYY"
                       nowTranslation="Hoy" required
                       @formatted="pickerRangeValuesFormatted = $event" />



            <slot></slot>

            <base-checkbox v-if="mostrarBorrados" class="px-2" v-model="Borrados">
              Mostrar solo borrados
            </base-checkbox>

            <div class="text-center">
              <hr class="my-md-4" />

              <base-button slot="footer" type="primary" native-type="submit">
                Aceptar
              </base-button>
            </div>

          </form>
        </ValidationObserver>
      </template>
    </modal>



  </div>
</template>

<script>

  import moment from 'moment'
  import Base from '@/api-base/base';
  import Servicio from '@/api-services/colaboradores.service';



  export default {
    name: 'Personal-Periodo-Select',
    components: {


    },
    props: {

      loading: { type: Boolean, default: false },

      value: {
        type: Object, default: () => {
          return {
            desde: '',
            hasta: ''
          };
        }
      },
      id: { type: String, default: null },
      disabled: { type: Boolean, default: false },
      required: { type: Boolean, default: false },

      // Same as MazInput (options: `sm|md|lg`)
      size: { type: String, default: null },

      // Nombre de la propiedad donde se guardará la fecha desde
      desde: { type: String, default: 'FHDesde' },
      // Nombre de la propiedad donde se guardará la fecha hasta
      hasta: { type: String, default: 'FHHasta' },
      // Etiqueta a mostrar
      placeholder: { type: String, default: 'Seleccione el rango de fechas' },

      Titulo: { type: String, default: 'Periodo' },
      Ayuda: { type: String, default: 'Selecciona un periodo de fecha y filtra los datos.' },


      // Etiqueta a mostrar
      placeholderDesde: { type: String, default: 'Desde' },
      // Etiqueta a mostrar
      placeholderHasta: { type: String, default: 'Hasta' },
      // Enable the dark mode
      dark: { type: Boolean, default: false },
      // Use color
      color: { type: String, default: 'primary' },

      // set the position of countries list (ex: `top`, `top right`, `bottom right`)
      position: { type: String, default: 'left bottom' },

      //valores: { type: Array, default: [] },
      //Mostrar al cargar
      mostrarAlCargar: { type: Boolean, default: true },
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      //Mostrar componente de fecha para el perido
      mostrarFechas: { type: Boolean, default: true },
      mostrarBorrados: { type: Boolean, default: false },
      ///Utilizada para guardar los datos adicionales en slot
      //Debe regresar verdadero para continuar, manda de parametro el objeto donde se guardarán los datos
      guardarAdicionales: { type: Function },
    },
    data() {
      return {
        modal0: false,

        cargandoFiltro: true,
        Filtros: null,
        valorFiltro: null,

        periodo: null,
        pickerRangeValuesFormatted: null,
        MazPickerCargado: true,

        OcultarCalendario: false,
        MostrarCalendario: false,

        Query: '',
        Colaboradores: [],
        PeriodoTexto: '',
        TieneFoco: false,



        Filtro: '',


        Borrados: false,
      }
    },
    computed: {
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      HayError() {
        return (this.periodo == null || this.periodo.end == null || this.periodo.start == null);
      },
      acPeriodo() {
        return `${this.$options.name}-Cp${Math.floor(Math.random() * 1000)}`;
      },
      FHHasta: {
        get: function () {
          //  console.debug(`${this.periodo.end}=FHHasta`);
          return this.periodo.end;
        },
        set: function (valor) {
          //   console.debug(`FHHasta=${valor}`)
          this.periodo.end = valor;
        }

      },
      FHDesde: {
        get: function () {
          //   console.debug(`${this.periodo.start}=FHDesde`);
          return this.periodo.start;
        },
        set: function (valor) {
          //console.debug(`FHDesde=${valor}`);
          this.periodo.start = valor;
        }

      },
    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.MostrarCalendario = true;
        }
        else
          this.MostrarCalendario = false;
      },
      MostrarCalendario(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
        else {
          this.$nextTick(() => {
            let modal = this.$refs.Modal;
            console.debug(modal);
          });

        }
      },

      Filtro(valor) {
        switch (valor) {
          case "Filtro":
            this.CargaFiltro();
            break;
        }
      },
      value(valor) {

        if (!valor)
          this.periodo = null;
        else

          this.periodo = {
            start: valor[this.desde],
            end: valor[this.hasta]
          };
      },

    },
    methods: {
      ObtenIcono(Tipo) {
        return Base.ObtenIcono(Tipo);
      },
      Filtrar(node, keyword) {
        ///Es padre
        if (node.label == node.value)
          return false;
        var Filtro = keyword.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toUpperCase();
        var Etiqueta = node.label.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toUpperCase();
        return (Etiqueta.indexOf(Filtro) > -1);
      },
      CargaFiltro() {
        if (this.Filtros != null)
          return;
        this.cargandoFiltro = true;
        Servicio.FiltroGets(this, {}, (response) => {

          var Filtros = [];
          var Puestos = [];
          response.data.puestos.forEach(e => {
            Puestos.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "Puestos", label: "Puestos", children: Puestos });

          var Departamentos = [];
          response.data.departamentos.forEach(e => {
            Departamentos.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "Departamentos", label: "Departamentos", children: Departamentos });

          var Grupos = [];
          response.data.grupos.forEach(e => {
            Grupos.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "Grupos", label: "Grupos", children: Grupos });

          var Sucursales = [];
          response.data.sucursales.forEach(e => {
            Sucursales.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "Sucursales", label: "Sucursales", children: Sucursales });

          var Empresas = [];
          response.data.empresas.forEach(e => {
            Empresas.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "Empresas", label: "Empresas", children: Empresas });


          var CentrosCostos = [];
          response.data.centrosCostos.forEach(e => {
            CentrosCostos.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "CentrosCostos", label: "Centros de Costos", children: CentrosCostos });

          var Turnos = [];
          response.data.turnos.forEach(e => {
            Turnos.push({ value: e.id, label: e.etiqueta });
          });
          Filtros.push({ value: "Turnos", label: "Turnos", children: Turnos });
          this.Filtros = Filtros;
          this.cargandoFiltro = false;
        })
      },
      OnMostrarCalendario() {
        if (this.OcultarCalendario) {
          this.OcultarCalendario = false;
          this.TieneFoco = false;
          return;
        }
        this.MostrarCalendario = true;
      },

      Atras() {
        var Dias = moment(this.FHHasta).diff(moment(this.FHDesde), 'days');

        this.FHHasta = moment(this.FHDesde).subtract(1, 'day').format("YYYY-MM-DD");
        this.FHDesde = moment(this.FHHasta).subtract(Dias, 'day').format("YYYY-MM-DD");
        // this.OcultarCalendario = true;

        this.Aceptar(true);


      },
      Adelante() {
        var Dias = moment(this.FHHasta).diff(moment(this.FHDesde), 'days');
        this.FHDesde = moment(this.FHHasta).add(1, 'day').format("YYYY-MM-DD");

        this.FHHasta = moment(this.FHDesde).add(Dias, 'day').format("YYYY-MM-DD");
        // this.OcultarCalendario = true;

        this.Aceptar(true);
      },
      Aceptar(NoCambiar) {
        if (this.mostrarFechas && this.HayError) {
          return
        }

        this.MostrarCalendario = false;

        

        var NValor = {};
        /*    if (this.value) {
              NValor = Object.assign({}, this.value);
            }*/
        NValor.PaginaNo = this.value.PaginaNo;
        NValor.PaginaLen = this.value.PaginaLen;
        NValor.Borrados = this.Borrados;
        if (NoCambiar == undefined || NoCambiar == false)
          NValor.PaginaNo = 0;
        if (this.mostrarFechas) {
          NValor[this.desde] = this.FHDesde;
          NValor[this.hasta] = `${moment(this.FHHasta).format("YYYY-MM-DD")}T23:59:59`;
        }
        var sFiltro = "";

        if (this.Filtro == "Filtro") {
          this.valorFiltro.forEach(f => {

            /*if (sFiltro == "")
              sFiltro = " > Filtro inteligente";*/
            if (f.length > 1) {
              if (NValor[f[0]] == undefined) {
                NValor[f[0]] = "";
                if (sFiltro != "")
                  sFiltro += " y ";
                sFiltro += `${f[0]}: `;
              }
              else {
                NValor[f[0]] += ",";
                sFiltro += ", ";
              }
              NValor[f[0]] += f[1];
              sFiltro += ", ";
              /*
              if (NValor[f[0]] == undefined)
                NValor[f[0]] = [];
              NValor[f[0]].push(f[1]);*/
            }

          });
          sFiltro = " > Filtro inteligente";
        }
        if (this.Filtro == "Colaboradores") {


          this.Colaboradores.forEach(c => {
            if (sFiltro == "")
              sFiltro = " > Personal seleccionado";
            if (NValor.Colaboradores == undefined)
              NValor.Colaboradores = "";
            else
              NValor.Colaboradores += ",";
            NValor.Colaboradores += c;
          });
        }

        if (this.guardarAdicionales != null && this.guardarAdicionales != undefined)
          if (!this.guardarAdicionales(NValor))
            return;

        this.PeriodoTexto = this.mostrarFechas ? `${moment(this.FHDesde).format("ddd DD/MMM/YY")} - ${moment(this.FHHasta).format("ddd DD/MMM/YY")}` : "";
        this.PeriodoTexto += sFiltro;



        this.$emit('input', NValor);
        this.$emit('cambio', NValor);
      }
    },

    mounted() {

      if (this.value != null)
        this.value = this.value;
      else {

      }





      if (this.mostrarAlCargar)
        this.MostrarCalendario = true;
      //this.Actualiza();
    },
  }

</script>


